import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../lib/hooks";
import { search } from "../lib/ReduxSlices/ViewSlice";
import { RadioGroup, Stack, Radio } from "@chakra-ui/react";
import { useAIsearchQuery, useSearchQuery } from "../lib/GraphAPI";

function Query(props: { query: string, setQuery: Function }) {
    let dispatch = useAppDispatch()

    const { data, error, isLoading, isSuccess } = useSearchQuery(props.query);
    if (data && isSuccess) {
        dispatch(search(data))
        props.setQuery(undefined)
    }

    return null
}

function AIQuery(props: { query: string, setQuery: Function }) {
    let dispatch = useAppDispatch()
    const { data, error, isLoading, isSuccess } = useAIsearchQuery(props.query);
    if (data && isSuccess) {
        dispatch(search(data.nodes))
        props.setQuery(undefined)
    }
    return null
}

export default function SearchBar(props: { style?: React.CSSProperties }) {
    const [searchTerm, setSearchTerm] = useState<string>()
    // mode can either be 'lexical' or 'semantic'
    const [mode, setMode] = useState<"lexical" | "semantic">("lexical")
    const [query, setQuery] = useState("")
    const handleChange = (event: any) => setSearchTerm(event.target.value)

    return (
        <InputGroup style={props.style} alignItems={"center"}>
            <InputLeftElement pointerEvents='none' top={2}>
                <SearchIcon color='gray.300' />
            </InputLeftElement>
            <Input type='search' placeholder='Search...' value={searchTerm}
                onChange={handleChange} onKeyDown={(event) => { if (event.code === 'Enter') setQuery(searchTerm!) }} />
            {query ? mode === "semantic" ? <AIQuery query={query} setQuery={setQuery} /> : <Query query={query} setQuery={setQuery} /> : null}
            <RadioGroup onChange={setMode} value={mode} marginLeft={"10px"}>
                <Stack direction={"column"}>
                    <Radio value="lexical">lexical</Radio>
                    <Radio value="semantic">semantic</Radio>
                </Stack>
            </RadioGroup>
        </InputGroup>
    )
}