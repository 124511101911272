import {
    Heading, Stack, Accordion,
    IconButton,
} from "@chakra-ui/react"
import { useAppDispatch, useAppSelector } from "../../lib/hooks"
import { ArrowRightIcon, PlusSquareIcon } from "@chakra-ui/icons"
import { motion } from "framer-motion";
import SavedFolder from "./SavedFolder";
import { uniqueNamesGenerator, Config, adjectives, animals } from 'unique-names-generator';
import { addFolder, setToolOpenStatus } from "../../lib/ReduxSlices/ViewSlice";

export default function SavedDocumentsList() {
    const open = useAppSelector(state => state.viewReducer.toolOpenStatus.savedCases)
    const folders = useAppSelector(state => state.viewReducer.folders)
    const toolOpenStatus = useAppSelector(state => state.viewReducer.toolOpenStatus)
    const dispatch = useAppDispatch()
    const variants = {
        open: { width: "400px" },
        closed: { width: "30px" },
    }
    const expand_button_variants = {
        open: { transform: "rotate(0deg)" }, // rotate button by 180deg
        closed: { transform: "rotate(180deg)" },
    }
    const header_variants = {
        open: { opacity: 1, display: "block", whiteSpace: "nowrap" }, // disallow linewrap with whitespace: nowrap
        closed: { display: "none", opacity: 0, whiteSpace: "nowrap" },
    }

    const customConfig: Config = {
        dictionaries: [adjectives, animals],
        separator: '-',
        length: 2,
    };
    return (
        <motion.div variants={variants} animate={open ? "open" : "closed"}>
            <div style={{ paddingRight: "20px", height: '100%', width: '100%', display: "flex", flexDirection: "column" }}>
                <Stack direction='row' alignItems='center' justifyContent={"space-between"} minHeight={"46px"} onClick={() => dispatch(setToolOpenStatus({ ...toolOpenStatus, savedCases: !open }))} style={{ cursor: "pointer" }}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <motion.div variants={expand_button_variants} animate={open ? "open" : "closed"}><ArrowRightIcon /></motion.div>
                        <motion.div variants={header_variants} animate={open ? "open" : "closed"}>
                            <Heading as='h2' size='lg'>Saved Cases</Heading>
                        </motion.div>
                    </Stack>
                    <motion.div variants={header_variants} animate={open ? "open" : "closed"}>
                        <IconButton aria-label="Add Folder" icon={<PlusSquareIcon />} onClick={() => { dispatch(addFolder(uniqueNamesGenerator(customConfig))) }} />
                    </motion.div>
                </Stack>
                <motion.div variants={header_variants} style={{ flex: 1, overflowY: "scroll", scrollbarWidth: "none" }}>
                    <Accordion
                        allowMultiple
                        width="100%"
                    >
                        {folders.map(folder => <SavedFolder folder={folder} key={folder.id} />)}
                    </Accordion>
                </motion.div>
            </div>
        </motion.div>)
}