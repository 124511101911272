import { useEffect, useState } from "react";
import Graph from "graphology";
import EdgeArrowProgram from "sigma/rendering/webgl/programs/edge.arrow";
import {
  addEdgeToGraph,
  addNodeToGraph,
  graphFromData,
} from "../lib/GraphFactory";
import {
  ControlsContainer,
  FullScreenControl,
  SearchControl,
  SigmaContainer,
  ZoomControl,
  useLoadGraph,
  useSigma,
} from "@react-sigma/core";
import "@react-sigma/core/lib/react-sigma.min.css";
import NodeTooltip from "../components/NodeTooltip";
import FloatingDocument from "../components/FloatingDocument";
import GraphEvents from "../components/Graph/Events";
import { useAppSelector } from "../lib/hooks";
import TimeFilter from "../components/Graph/controls/TimeFilter";
import NodeContextMenu from "../components/Graph/controls/NodeContextMenu";
import { useGetNeighboursQuery, useGetRecommendedNeighboursQuery } from "../lib/GraphAPI";
import { store } from "../lib/Store";
import Filter from "../components/Graph/controls/Filter";
import QuickFilter from "../components/Graph/controls/QuickFilter";
import Timeline from "../components/Graph/Timeline";
import { Spinner } from "@chakra-ui/react";
import Toolbar from "../components/Toolbar";
import SearchControlWrapper from "../components/SearchControlWrapper";

const LoadNeighbours = (props: { id: string }) => {
  const { data, error, isLoading, isSuccess } = useGetNeighboursQuery({
    id: props.id,
    level: 1,
  });

  const sigma = useSigma();
  const graph = sigma.getGraph();
  useEffect(() => {
    if (data && isSuccess) {
      for (let i = 0; i < data.nodes.length; i++) {
        if (!graph.hasNode(data.nodes[i].elementId)) {
          let node = data.nodes[i];
          addNodeToGraph(node, graph);
        }
      }
      for (let i = 0; i < data.edges.length; i++) {
        if (!graph.hasEdge(data.edges[i].elementId)) {
          let edge = data.edges[i];
          addEdgeToGraph(edge, graph);
        }
      }
    }
  }, [props.id, data]);

  return null;
};
const RecommendNeighbours = (props: { id: string, nodeId: string }) => {
  const { data, error, isLoading, isSuccess } = useGetRecommendedNeighboursQuery(props.id);

  const sigma = useSigma();
  const graph = sigma.getGraph();
  useEffect(() => {
    if (data && isSuccess) {
      for (let i = 0; i < data.nodes.length; i++) {
        if (!graph.hasNode(data.nodes[i].elementId)) {
          let node = data.nodes[i];
          addNodeToGraph(node, graph);
          // Set color based on distance
          graph.addUndirectedEdge(props.nodeId, node.elementId, { distance: data.distances[i], size: 10 * (1 - data.distances[i]) });
        }
      }
    }
  }, [props.id, data]);

  return null;
};

const LoadGraph = (props: { graph: Graph }) => {
  const loadGraph = useLoadGraph();
  const sigma = useSigma();
  useEffect(() => {
    loadGraph(props.graph);
    sigma.refresh();
  }, []);

  return null;
};

export const GraphTabLayout = (props: {
  graphViewIndex: number;
  isActive?: boolean;
}) => {
  const { data, error, isLoading, isSuccess } = useGetNeighboursQuery({
    id: store.getState().viewReducer.graphViews[props.graphViewIndex]
      .centerNode,
    level: 1,
  });
  const [loadNeighbours, setLoadNeighbours] = useState<string>();
  const [recommendNeighbours, setRecommendNeighbours] = useState<{ id: string, nodeId: string }>();
  // const [timelinePosition, setTimelinePosition] = useState<{
  //   x: number; y: number;
  // }>();
  const [hoveredNode, onHover] = useState<{
    event: { x: number; y: number };
    node: string;
  }>();
  const [clickedNode, onClick] = useState<{
    event: { x: number; y: number };
    node: string;
  }>();
  const [floatingDocuments, setFloatingDocuments] = useState<Array<{ nodeId: string, id: string, url: string, coords: { x: number, y: number }, graphViewIndex: number }>>([])
  const nodeContextMenu = useAppSelector(
    (state) =>
      state.viewReducer.graphViews[props.graphViewIndex].nodeContextMenu
  );
  const openTools = useAppSelector(
    (state) => state.viewReducer.toolOpenStatus
  )
  
  if (isLoading) {
    return (
      <>
        Loading <Spinner />
      </>
    );
  }
  if (data && isSuccess) {
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "row" }}>
        <style>
          {`
            .sigma-container {
              margin-left: 70px;
              background-color: #E2E8F0;
              flex-grow: 1;
            }
          `}
        </style>
        <SigmaContainer
          style={{ height: "100%", width: "100%", display: "flex" }}
          settings={{
            hoverRenderer: () => { },
            defaultEdgeType: "arrow",
            edgeProgramClasses: { arrow: EdgeArrowProgram },
          }}
        >
          <Toolbar />
          <LoadGraph
            graph={graphFromData({
              data: data,
              centerNode:
                store.getState().viewReducer.graphViews[props.graphViewIndex]
                  .centerNode,
            })}
          />
          <GraphEvents
            onClickNode={onClick}
            onHoverNode={onHover}
            graphViewIndex={props.graphViewIndex}
            isActive={props.isActive}
            floatingDocuments={floatingDocuments}
            setFloatingDocuments={setFloatingDocuments}
          // setTimelinePosition={setTimelinePosition}
          />
          <ControlsContainer position="top-right">
            <SearchControlWrapper>
              <SearchControl />
            </SearchControlWrapper>
          </ControlsContainer>
          {hoveredNode ? (
            <NodeTooltip
              id={hoveredNode.node}
              position={hoveredNode.event}
            />
          ) : (
            <></>
          )}
          {floatingDocuments.map((doc) => (
            <FloatingDocument
              key={doc.id}
              id={doc.id}
              url={doc.url}
              position={doc.coords}
              onClose={(id: string) => {
                let floats = [...floatingDocuments]
                const index = floats.findIndex(node => node.id === id)
                if (index === 0 || index) {
                  floats.splice(index, 1)
                  setFloatingDocuments(floats)
                }
              }} />
          ))}
          <TimeFilter
            style={{ position: "absolute", bottom: "20px", left: "30%" }}
            graphViewIndex={props.graphViewIndex}
          />
          <Filter
            style={{ position: "absolute", top: "20px", left: "100px", width: "300px" }}
            graphViewIndex={props.graphViewIndex}
          />
          <QuickFilter
            style={{ position: "absolute", right: "20px", bottom: "20px" }}
            graphViewIndex={props.graphViewIndex}
          />
          {/* <Timeline style={{ position: "absolute", left: timelinePosition?.x, top: timelinePosition?.y }} /> */}
          {nodeContextMenu ? (
            <NodeContextMenu
              id={nodeContextMenu.id}
              graphViewIndex={props.graphViewIndex}
              nodeId={nodeContextMenu.nodeId}
              position={nodeContextMenu.coords}
              loadNeighbours={(id: string) => {
                setLoadNeighbours(id);
              }}
              recommendNeighbours={(id: string, nodeId: string) => {
                setRecommendNeighbours({ id, nodeId });
              }}
            />
          ) : null}
          {loadNeighbours ? <LoadNeighbours id={loadNeighbours} /> : null}
          {recommendNeighbours ? <RecommendNeighbours id={recommendNeighbours.id} nodeId={recommendNeighbours.nodeId} /> : null}
        </SigmaContainer>
      </div>
    );
  } else {
    return <>"Error loading the Graph"</>;
  }
};
