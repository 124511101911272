import React from "react";
import { motion } from "framer-motion";
import { useAppSelector } from "../lib/hooks";


export default function SearchControlWrapper(props: { children: React.ReactNode }) {
    const open = useAppSelector((state) => state.viewReducer.toolOpenStatus.search)

    const variants = {
        open: { opacity: 1 },
        closed: { opacity: 0, display: "none" },
    }
    return <motion.div variants={variants} animate={open ? "open" : "closed"}>
        {props.children}
    </motion.div>
}