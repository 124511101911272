import { useAuth } from "oidc-react";
import { Link } from "react-router-dom";
import { store } from "../lib/Store";
import TabLayout from "../layouts/TabLayout"
import { token } from "../lib/ReduxSlices/AuthSlice";
export default function Index() {
  var auth = useAuth();
  if (auth.userData) {
    store.dispatch(token(auth.userData?.access_token));
    return (
      <TabLayout/>
    );
  } else {
    return (
      <>
        You are not logged in and can't access this content <p/>
        <button onClick={() => auth.signIn()}>Login</button>
      </>
    );
  }
}
