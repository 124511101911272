interface Graph {
  edges: Array<Relationship>;
  nodes: Array<Node>;
}

interface Node {
  elementId: string;
  labels: string[];
  properties: {
    id: string;
    label?: string;
    appno: string;
    country: Country[];
    importance: Importancetype;
    judgementdate?: string;
    description?: string;
    conclusion: any;
    chambertype: Chambertype;
    separateopinion: boolean;
    keywords: string[];
    violation: string;
    nonviolation: string;
    url: string;
  };
}

interface Relationship {
  elementId: string;
  startNodeElementId: string;
  endNodeElementId: string;
  type: string;
  properties: {
    weight: number;
  };
}
export const ImportanceNames = [
  "EurLex",
  "Key Case",
  "Level 1",
  "Level 2",
  "Level 3",
];
type Importancetype = 0 | 1 | 2 | 3 | 4;
let importancetypes: Importancetype[] = [0, 1, 2, 3, 4];

type Chambertype = "COMMITTEE" | "CHAMBER" | "GRANDCHAMBER";
let chambertypes: Chambertype[] = ["COMMITTEE", "CHAMBER", "GRANDCHAMBER"];

type Country =
  | "SRB"
  | "BGR"
  | "RUS"
  | "POL"
  | "ARM"
  | "LTU"
  | "GBR"
  | "ROU"
  | "SWE"
  | "SVN"
  | "ESP"
  | "AZE"
  | "AUT"
  | "CHE"
  | "SVK"
  | "UKR"
  | "HRV"
  | "DEU"
  | "GRC"
  | "HUN"
  | "BIH"
  | "TUR"
  | "MLT"
  | "NOR"
  | "ITA"
  | "CYP"
  | "FRA"
  | "BEL"
  | "ALB"
  | "MDA"
  | "FIN"
  | "SMR"
  | "CZE"
  | "MNE"
  | "MKD"
  | "EST"
  | "NLD"
  | "LVA"
  | "DNK"
  | "PRT"
  | "GEO"
  | "LIE"
  | "IRL"
  | "ISL"
  | "LUX"
  | "AND";

let countrytypes: Country[] = [
  "SRB",
  "BGR",
  "RUS",
  "POL",
  "ARM",
  "LTU",
  "GBR",
  "ROU",
  "SWE",
  "SVN",
  "ESP",
  "AZE",
  "AUT",
  "CHE",
  "SVK",
  "UKR",
  "HRV",
  "DEU",
  "GRC",
  "HUN",
  "BIH",
  "TUR",
  "MLT",
  "NOR",
  "ITA",
  "CYP",
  "FRA",
  "BEL",
  "ALB",
  "MDA",
  "FIN",
  "SMR",
  "CZE",
  "MNE",
  "MKD",
  "EST",
  "NLD",
  "LVA",
  "DNK",
  "PRT",
  "GEO",
  "LIE",
  "IRL",
  "ISL",
  "LUX",
  "AND",
];

interface File {
  docname: string;
  id: string;
  summary: string;
  body: string;
}

export { chambertypes, importancetypes, countrytypes };
export type {
  Graph,
  Relationship,
  Node,
  File,
  Chambertype,
  Importancetype,
  Country,
};
