import { Input, Tab, useToast } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import { updateGraphView } from "../lib/ReduxSlices/ViewSlice";

export default function GraphTabLayoutHeader(props: {
  graphViewIndex: number;
}) {
  const graphView = useAppSelector(
    (state) => state.viewReducer.graphViews[props.graphViewIndex]
  );
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const toast = useToast();

  const [name, setName] = useState(graphView.name);
  const [renaming, setRenaming] = useState(false);
  return (
    <Tab
      onDoubleClick={() => setRenaming(true)}
      onFocus={() => inputRef.current?.focus()}
    >
      {renaming ? (
        <Input
          ref={inputRef}
          placeholder="name"
          onChange={(event) => setName(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (name === "") {
                toast({
                  title: "Name too short",
                  description: "The provided name is too short",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
                return
              }

              setRenaming(false);
              let newGraphView = { ...graphView };
              newGraphView.name = name;

              dispatch(updateGraphView({
                graphViewIndex: props.graphViewIndex,
                graphView: newGraphView,
              }));
            }
          }}
          value={name}
          autoFocus={true}
        ></Input>
      ) : (
        name
      )}
    </Tab>
  );
}
