import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useGetFileQuery } from "../lib/GraphAPI";
import { useAppSelector } from "../lib/hooks";

export const FileViewer = (props: { id: string, isOpen: boolean, onClose: () => void }) => {
  const openDoc = useAppSelector(state => state.viewReducer.openDocument)

  const { data, error, isLoading, isSuccess } = useGetFileQuery(openDoc!);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (error) {
    return <></>;
  }

  if (isSuccess) {

    return <div>
      <Modal isOpen={props.isOpen} onClose={props.onClose} scrollBehavior="inside" size="xl"      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{data.docname}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.summary}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={props.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal></div>;
  } else {
    return <>Something went wrong...</>;
  }


};
