import { Text, Tooltip, theme } from "@chakra-ui/react";
import { CSSProperties } from "react";
import { getNodeColor } from "../../../lib/GraphFactory";
import { useAppSelector, useAppDispatch } from "../../../lib/hooks";
import { ImportanceNames, importancetypes } from "../../../lib/APITypes";
import { importance_level_tooltips } from "../../../strings/tooltips"
import { addImportanceFilter, removeImportanceFilter } from "../../../lib/ReduxSlices/ViewSlice";
export default function QuickFilter(props: {
  style: CSSProperties;
  graphViewIndex: number;
}) {

  let dispatch = useAppDispatch();
  let importanceFilter = useAppSelector(
    (state) =>
      state.viewReducer.graphViews[props.graphViewIndex].filter.importance
  );
  return (
    <div
      style={{
        ...props.style,
        backgroundColor: "rgba(255,255,255,0.9)",
        borderRadius: "10px",
        padding: "10px",
        zIndex: "100"
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Tooltip label="Click on the importance levels below to filter in or out judgments based on importance officially classified by the ECtHR. Cases are divided into four categories, with the highest level of importance being 'Key cases', followed by levels 1, 2 and 3.">
            <Text as="b" style={{ textAlign: "center" }}>
              Importance
            </Text>
          </Tooltip>
          {importancetypes.map((value) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              onClick={() => {
                if (!importanceFilter.includes(value)) {
                  dispatch(addImportanceFilter({
                    graphViewIndex: props.graphViewIndex,
                    importance: value
                  }))
                } else {
                  dispatch(removeImportanceFilter({
                    graphViewIndex: props.graphViewIndex,
                    importance: value,
                  }));
                }
              }}
            >
              <Tooltip label={importance_level_tooltips[value]}>
                <Text
                  textColor={
                    importanceFilter.includes(value)
                      ? "black"
                      : theme.colors.gray[400]
                  }
                >
                  {ImportanceNames[value]}
                </Text>
              </Tooltip>

              <div
                style={{
                  backgroundColor: importanceFilter.includes(value)
                    ? getNodeColor(value.toString())
                    : theme.colors.gray[400],
                  borderRadius: "20px",
                  width: "17px",
                  height: "17px",
                  marginLeft: "10px",
                }}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "10px",
          }}
        >
          <Tooltip label="Click on the case details below to easily filter in or out judgments based on the contents of the case- Hover above each filter to see more information.">
            <Text as="b" style={{ textAlign: "center" }}>
              Case Details
            </Text>
          </Tooltip>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip label="Filter in or out judgments where the Court has not found a violation of the Convention or a Protocol Article.">
                No Violation
              </Tooltip>
              <div
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                  width: "17px",
                  height: "17px",
                  marginLeft: "10px",
                  borderWidth: "3px",
                  borderColor: theme.colors.green[400],
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip label="Filter in or out judgments where the Court has found a violation of the Convention or a Protocol Article.">
                Violation
              </Tooltip>
              <div
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                  width: "17px",
                  height: "17px",
                  marginLeft: "10px",
                  borderWidth: "3px",
                  borderColor: theme.colors.red[500],
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip label="Filter in or out judgments where there has neither been found a violation, or a non-violation. This could for example be a friendly settlement, strike-out or just satisfaction.">
                Miscellaneous
              </Tooltip>
              <div
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                  width: "17px",
                  height: "17px",
                  marginLeft: "10px",
                  borderWidth: "3px",
                  borderColor: theme.colors.cyan[400],
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip label="Filter in or out judgments you have saved. These can be found in the list on the left-hand sidebar.">
                Saved in List
              </Tooltip>
              <div
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                  width: "17px",
                  height: "17px",
                  marginLeft: "10px",
                  borderWidth: "3px",
                  borderColor: theme.colors.purple[400],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
