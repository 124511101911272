import { UnknownAction, configureStore, createSlice } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { graphApi } from "./GraphAPI";
import viewReducer from "./ReduxSlices/ViewSlice"
import authReducer  from "./ReduxSlices/AuthSlice";
import quackReducer  from "./ReduxSlices/QuackSlice";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [graphApi.reducerPath]: graphApi.reducer,
    authReducer: authReducer,
    quackReducer: quackReducer,
    viewReducer: viewReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(graphApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
