import { useGetFileQuery, useGetNodeQuery } from "../lib/GraphAPI"
import { motion } from "framer-motion";
import { Button, Card, CardBody, CardFooter, CloseButton, HStack, Heading, Spinner, Stack, Tag, Text, Tooltip, theme } from "@chakra-ui/react";
import { ImportanceNames } from "../lib/APITypes";

export default function NodeTooltip(props: { id: string, position: { x: number, y: number } }) {
    // const { data: fileData, error: fileError, isLoading: fileIsLoading, isSuccess: fileIsSuccess } = useGetFileQuery(props.id);
    const { data: nodeData, error: nodeError, isLoading: nodeIsLoading, isSuccess: nodeIsSuccess } = useGetNodeQuery(props.id);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div style={{ width: 400, height: 'fit-content', position: "absolute", top: props.position.y + 20, left: props.position.x - 200}}>
                <Card width="100%" zIndex={10} style={{ boxShadow: "0px 4px 20px -4px #00000080" }}>
                    <CardBody>
                        <Stack spacing="3">
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                {nodeIsLoading ? (
                                    <Spinner />
                                ) : (
                                    <Heading size="md">
                                        {nodeData ? nodeData.properties.label : "Something went wrong"}
                                    </Heading>
                                )}
                            </Stack>
                            <HStack spacing={1} flexWrap={"wrap"}>
                                {nodeData?.properties.importance ? <Tag variant={"solid"} bgColor={theme.colors.blue[900]}>{ImportanceNames[nodeData?.properties.importance]}</Tag> : null}
                                {nodeData?.properties.violation ? nodeData?.properties.violation.split(",").map(violation => <Tag bgColor={theme.colors.red[600]} textColor={"white"}>Article {violation}</Tag>) : null}
                                {nodeData?.properties.nonviolation ? nodeData?.properties.nonviolation.split(",").map(nonviolation => <Tag bgColor={theme.colors.green[600]} textColor={"white"}>Article {nonviolation}</Tag>) : null}
                                {nodeData?.properties.keywords ? nodeData?.properties.keywords.map(keyword => <>
                                    <Tooltip label={keyword}>
                                        <Tag bgColor={theme.colors.yellow[500]} textColor={"white"}>{keyword.length > 10 ? keyword.substring(0, 10) + "..." : keyword} </Tag>
                                    </Tooltip>
                                </>) : null}
                            </HStack>
                            {nodeData ? <HStack spacing={2}>
                                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>Date: {new Date(nodeData.properties.judgementdate!).toLocaleDateString('de-DE')} </Text>
                                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>|</Text>
                                <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>Appno: {<>
                                    <Tooltip label={nodeData.properties.appno.replaceAll(";", "  ")}>
                                        {nodeData.properties.appno.length > 10 ? nodeData.properties.appno.substring(0, 10) + "..." : nodeData.properties.appno}
                                    </Tooltip>
                                </>}</Text>
                            </HStack> : <Spinner />}
                        </Stack>
                    </CardBody>
                </Card>  
            </div>
        </motion.div>)
}