import { Folder } from "../../lib/ViewTypes";
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Input,
    Stack,
    Text,
} from "@chakra-ui/react"
import { useState } from "react";
import { useAppDispatch } from "../../lib/hooks";
import SavedDocumentCard from "./SavedDocumentCard";
import { updateFolder } from "../../lib/ReduxSlices/ViewSlice";

export default function SavedFolder(props: { folder: Folder }) {
    const [renameMode, setRenameMode] = useState(false)
    const [name, setName] = useState(props.folder.name)
    const dispatch = useAppDispatch()
    return (<AccordionItem>
        <h2>
            <AccordionButton>
                {renameMode ? <Input onKeyDown={event => {
                    if (event.key === "Enter") {
                        dispatch(updateFolder({ id: props.folder.id, name: name, documents: props.folder.documents }))
                        setRenameMode(false)
                    }
                }} onChange={event => setName(event.target.value)} value={name} /> : <div style={{ display: "flex", flexDirection: "row", width: "100%" }} onDoubleClick={() => setRenameMode(true)}>
                    <Text as="b">{props.folder.name}</Text>
                </div>}
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
                <Stack spacing={1}>
                    {props.folder.documents.map(document => <SavedDocumentCard key={document} id={document} nodeId="" folder={props.folder} />)}
                </Stack>
            </AccordionPanel>
        </h2>
    </AccordionItem>)
}