import { useEffect } from "react";
import "./App.css";
import { AuthProvider, useAuth } from "oidc-react";
import { store } from "./lib/Store";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { token } from "./lib/ReduxSlices/AuthSlice";

function App() {
  useEffect(() => {
    document.title = "Meshr";
  }, []);
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <AuthProvider
          authority={
            "https://auth.meshr.org/realms/" +
            (process.env.REACT_APP_AUTH_REALM
              ? process.env.REACT_APP_AUTH_REALM
              : "no auth realm set")
          }
          clientId="frontend"
          redirectUri={
            process.env.REACT_APP_REDIRECT_URL
              ? process.env.REACT_APP_REDIRECT_URL
              : "http://localhost:3000"
          }
          onSignIn={(user) => {
            // the `user` prop is actually the data the app received from `/userinfo` endpoint.
            store.dispatch(token(user?.access_token));
          }}
          autoSignIn={true}
        >
          <Provider store={store}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Index />} />
              </Routes>
            </BrowserRouter>
          </Provider>
        </AuthProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
