import { GraphTabLayout } from "./GraphTabLayout";
import SavedDocumentsList from "../components/SavedDocumentsList/SavedDocumentsList";
import {
  Box,
  Flex,
  Img,
  Menu,
  MenuList,
  Tooltip,
  UseToastOptions,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FileViewer } from "../components/FileViewModal";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import { useEffect, useRef, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import MainTabLayout from "./MainTabLayout";
import { saveAs } from "file-saver";
import { store } from "../lib/Store";
import GraphTabLayoutHeader from "./GraphTabLayoutHeader";
import { openDocument, setView } from "../lib/ReduxSlices/ViewSlice";
import { GraphView } from "../lib/ViewTypes";
import { useGetFileQuery } from "../lib/GraphAPI";

export default function TabLayout() {
  const openDoc = useAppSelector((state: any) => state.viewReducer.openDocument);
  const graphViews = useAppSelector((state: any) => state.viewReducer.graphViews);
  const [tabIndex, setTabIndex] = useState(0);
  const [projectContextMenueOpen, setProjectContextMenueOpen] = useState(false);
  const { data, error, isLoading, isSuccess } = useGetFileQuery("hudoc:001-58287");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  const closeDialog = () => {
    dispatch(openDocument("undefined"));
    onClose();
  };

  useEffect(() => {
    onOpen();
  }, [openDoc, onOpen]);

  const toast = useToast();

  const inputFile = useRef<HTMLInputElement | null>(null);

  const fileErrorToast: UseToastOptions = {
    title: "Problem Loading File",
    description: `There was a problem with the provided File`,
    status: "error",
    duration: 5000,
    isClosable: true,
  };

  const apiErrorToast: UseToastOptions = {
    title: "HUDOC API Error",
    description: `The HUDOC API returned an error. Unfortunately we don't have control over this. Please try again later`,
    status: "error",
    duration: 10000,
    isClosable: true,
  }

  useEffect(() => {
    if (!isLoading && error) {
      console.log(error)
      toast(apiErrorToast);
    }
  }, [error, isLoading, isSuccess, data])


  return (
    <>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={(event) => {
          const reader = new FileReader();
          if (event.target.files == null) {
            toast(fileErrorToast);
            return;
          }
          const selectedFile = event.target.files[0];
          // Define a callback function to handle the file reading
          reader.onload = (e) => {
            if (e.target == null) {
              toast(fileErrorToast);
              return;
            }
            const fileContent = e.target.result;
            let parsedFileContent;
            try {
              parsedFileContent = JSON.parse(fileContent?.toString()!);
            } catch (error) {
              toast(fileErrorToast);
              return;
            }
            if (!parsedFileContent || !parsedFileContent.graphViews || !parsedFileContent.folders) {
              toast(fileErrorToast);
              return
            }
            dispatch(setView(parsedFileContent));
          };

          // Read the contents of the selected file as text
          reader.readAsText(selectedFile);
        }}
      />

      <Flex
        direction={"row"}
        width="100%"
        height="100%"
        style={{ overflow: "hidden" }}
      >
        <Box flexGrow={1}>
          <Tabs
            width="100%"
            height="100%"
            onChange={(index) => setTabIndex(index)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
              }}
            >
              <TabList width="100%" zIndex="100" backgroundColor="white">
                <Tab
                  onContextMenu={(event) => {
                    event.preventDefault();
                    setProjectContextMenueOpen(true);
                  }}
                >
                  <Menu
                    isOpen={projectContextMenueOpen}
                    colorScheme="purple"
                    closeOnSelect={true}
                    closeOnBlur={true}
                    onClose={() => setProjectContextMenueOpen(false)}
                  >
                    <Tooltip label="Go to Main Page">
                      <div>
                        <Img src="/logo512.png" width={30} />
                      </div>
                    </Tooltip>
                    <MenuList>
                      <div
                        onClick={() => {
                          var blob = new Blob(
                            [JSON.stringify(store.getState().viewReducer)],
                            { type: "text/plain;charset=utf-8" }
                          );
                          saveAs(blob, "meshr_view.json");
                        }}
                      >
                        Save to File
                      </div>
                      <div onClick={() => inputFile.current?.click()}>
                        Load from File
                      </div>
                    </MenuList>
                  </Menu>
                </Tab>
                {graphViews.map((graphView: GraphView, index: number) => (
                  <GraphTabLayoutHeader graphViewIndex={index} key={index} />
                ))}
              </TabList>

              <TabPanels width="100%" height="100%">
                <TabPanel
                  height="100%"
                  overflowY="scroll"
                  style={{ scrollbarWidth: "thin" }}
                >
                  <MainTabLayout />
                </TabPanel>
                {graphViews.map((graphView: GraphView, index: number) => (
                  <TabPanel width="100%" height="100%" key={index} padding={0}>
                    <GraphTabLayout
                      key={index}
                      graphViewIndex={index}
                      isActive={index === tabIndex - 1}
                    />
                  </TabPanel>
                ))}
              </TabPanels>
            </div>
          </Tabs>
        </Box>
        <Box >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              flexGrow: 1,
            }}
          >
            <SavedDocumentsList />
          </div>
        </Box>
      </Flex>
      <FileViewer id={openDoc!} isOpen={isOpen} onClose={closeDialog} />
    </>
  );
}
