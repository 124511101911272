import { PhoneIcon, SearchIcon } from "@chakra-ui/icons";
import { Icon, Stack, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { Filter as FilterIcon, Bookmark, ArrowUpCircle, ZoomIn, ZoomOut } from 'react-feather';
import Delete  from '@mui/icons-material/Delete';
import { motion } from "framer-motion";
import { useSigma } from "@react-sigma/core";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import { setToolOpenStatus } from "../lib/ReduxSlices/ViewSlice";
import theme from "../theme";

function IconButton(props: { icon: ReactElement, text: string, onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }) {
    return <Stack alignItems='center' cursor="pointer" onClick={props.onClick ? e => props.onClick!(e) : undefined} >
        {props.icon}
        <Text userSelect={"none"} fontSize='x-small' fontWeight={700} textAlign="center">{props.text}</Text>
    </Stack>
}

export default function Toolbar() {
    const toolOpenStatus = useAppSelector(state => state.viewReducer.toolOpenStatus)
    const dispatch = useAppDispatch()
    const variants = {
        open: { backgroundColor: theme.colors.purple[100] },
        closed: { backgroundColor: theme.colors.white },
    }

    const sigma = useSigma()
    return <div style={{ position: "absolute", height: "100%", left: "0px", top: "0px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}> 
        <div style={{ backgroundColor: "white", padding: "10px", borderRadius: "5px", width: "70px", height: "100%" }}>
            <Stack justifyContent={"space-between"} style={{ height: "100%" }}>
            <Stack alignItems="center" justifyContent={"space-evenly"} flexGrow={2}>
                <motion.div style={{ borderRadius: "5px", padding: "5px", width: "100%" }} animate={toolOpenStatus.search ? "open" : "closed"} variants={variants}>
                    <IconButton icon={<SearchIcon />} text="Search" onClick={e => dispatch(setToolOpenStatus({ ...toolOpenStatus, search: !toolOpenStatus.search }))} />
                </motion.div>
                <motion.div style={{ borderRadius: "5px", padding: "5px", width: "100%" }} animate={toolOpenStatus.advancedFilters ? "open" : "closed"} variants={variants}>
                    <IconButton icon={<FilterIcon />} text="Advanced Filter" onClick={e => {
                        dispatch(setToolOpenStatus({ ...toolOpenStatus, advancedFilters: !toolOpenStatus.advancedFilters }))
                    }
                    } />
                </motion.div>
                <motion.div style={{ borderRadius: "5px", padding: "5px", width: "100%" }} animate={toolOpenStatus.savedCases ? "open" : "closed"} variants={variants}>
                    <IconButton icon={<Bookmark />} text="Saved Cases" onClick={
                        e => {
                            dispatch(setToolOpenStatus({ ...toolOpenStatus, savedCases: !toolOpenStatus.savedCases }))
                        }
                    } />
                </motion.div>
                <motion.div style={{ borderRadius: "5px", padding: "5px", width: "100%" }} animate={toolOpenStatus.timeFilter ? "open" : "closed"} variants={variants}>
                    <IconButton icon={<ArrowUpCircle />} text="TimeFilter" onClick={
                        e => {
                            dispatch(setToolOpenStatus({ ...toolOpenStatus, timeFilter: !toolOpenStatus.timeFilter }))
                        }
                    } />
                </motion.div>
            </Stack>
            <div style={{ width: "100%", height: "1px", backgroundColor: "black", marginTop: "10px", marginBottom: "10px" }} />
            <Stack justifyContent={"space-evenly"} flexGrow={1} alignItems={"center"}>
                <IconButton icon={<ZoomIn />} text="Zoom In" onClick={e => {
                    sigma.getCamera().animatedZoom(1.5)
                }} />
                <Text fontSize="x-small" as='b'>{Math.round(sigma.getCamera().ratio * 100)}%</Text>
                <IconButton icon={<ZoomOut />} text="Zoom Out" onClick={e => sigma.getCamera().animatedZoom(0.5)} />
                <IconButton icon={<PhoneIcon />} text="Center Graph" onClick={e => sigma.getCamera().animatedReset()} />
            </Stack>            
        </Stack>
        </div>
    </div>
}